import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import styles from '../templates/pageTemplate.module.scss';

const PostLink = ({ post }) => (
	<div>
		<Link to={post.frontmatter.slug}>{post.frontmatter.title} ({post.frontmatter.date})</Link>
	</div>
);

PostLink.propTypes = {
	post: PropTypes.shape({}).isRequired
};

const Blog = ({
	data: {
		allMarkdownRemark: { edges }
	}
}) => {
	return (
		<Layout>
			<SEO title="Blog" description="" />
			<article>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-12">
							<h1>Blog</h1>
							<div className="list">
								{edges
									.filter(edge => edge.node.frontmatter.type === 'blog')
									.map(edge => <PostLink key={edge.node.id} post={edge.node} />)
								}
							</div>
						</div>
					</div>
				</div>
			</article>
		</Layout>
	);
};

Blog.propTypes = {
	data: PropTypes.shape({}).isRequired
};

export const pageQuery = graphql`
	query {
	  allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
	    edges {
	      node {
	        id
	        excerpt(pruneLength: 250)
	        frontmatter {
	          date(formatString: "MMMM DD, YYYY")
	          slug
	          title
						type
	        }
	      }
	    }
	  }
	}
`;

export default Blog;
